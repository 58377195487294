<template>
  <div id="app">  
    <router-view></router-view>
  </div>
</template>

<script>
export default{
  data(){
    return {}
  },
  created() {},
  mounted() {},
  methods:{},
}
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  height 100%
  a{
    text-decoration none
  }
</style>
