import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//导入文件   时间
import moment from 'moment'
Vue.prototype.$moment = moment;

//数据请求方式
import Provider from "./provider/index.js"
Vue.prototype.$provider = Provider; 

//数据请求方式
import Pulick from "./config/pulick.js"
Vue.prototype.$pulick = Pulick; 

//整体架构样式表   //公用  图标
import './assets/css/icon-font.css'
//整体架构样式表   //公用  style
import '../public/css/style-all.css'

Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
