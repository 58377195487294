import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import { Message } from 'element-ui'
import { Loading } from 'element-ui'
import router from '@/router'
import { config } from '@/config'

class LoadingService {
  count = 0
  service = null
  openLoading() {
    this.count++
    this.service = Loading.service();
  }
  closeLoading() {
    setTimeout(() => {
      this.count--
      if (this.count <= 0) {
        this.service.close();
      }
    }, 0)
  }
}
const loadingInstance = new LoadingService();

const successCode = 200 // 前后端协调的正确响应状态
const http = axios.create({
  timeout: 300000,
  //withCredentials: true, //跨域
  baseURL: config[process.env.NODE_ENV].serverUrl,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const requestInterceptor = http.interceptors.request.use(function(config) {
  // config.url = '/api' + config.url
  try {
		//debugger
    let { tokenInfo } = store.state;
		tokenInfo = JSON.parse(sessionStorage.getItem("token_xjfj")).tokenInfo;
		config.headers['wg-auth'] = tokenInfo;
    //loadingInstance.openLoading()
    return config
  } catch (e) {
    Message.error('网络连接失败');
    return Promise.reject(error)
  }
}, function(error) {
  //loadingInstance.closeLoading();
  Message.error(error);
  return Promise.reject(error)
})

const responseInterceptor = http.interceptors.response.use(function(response) {
  if(!response.data.metadata){
    try {
      if (response.data.code != successCode) {
        Message.error(response.data.msg)
      }
      if ([405, 406, 407, 408, 409].includes(response.data.code)) {
        setTimeout(() => {
          if (process.env.NODE_ENV === 'development') {
            router.push({
              name: 'login',
              query: {
                redirect: router.currentRoute.path,
                ...router.currentRoute.query
              }
            });
          }
        }, 1000)
        return
      }
    } catch (e) {
      Message.error(e)
    }
  }
  //loadingInstance.closeLoading()
  return Promise.resolve(response.data);
}, function({
  response
}) {
  //loadingInstance.closeLoading()
  let errorMsg = ''
  switch (response.status) {
    case 400:
      errorMsg += '用户请求错误，请检查发送的参数';
      break;
    case 401:
      /* router.push({ path: '/' }); */
			//清除认证信息等存储   返回登录页面
			sessionStorage.clear();
			window.location.href = "/xjfj";//新疆系统登录入口
			//window.location.href = "http://172.31.67.173:8084/fincloud";//中心
			//window.location.href = "https://jinan.wengegroup.com/fincloud";//公司内网
      return;
      // store.commit('user/saveToken', {});
      // store.commit('user/setUser', {});
      // errorMsg = '登录权限验证过期，请重新登录';
      break;
    case 404:
      errorMsg += '请求接口不存在';
      break;
    case 500:
      errorMsg += '服务器发生错误';
      break;
    case 504:
      errorMsg += '服务器发生错误';
      break;
    default:
      errorMsg += response.statusText
      break;
  }
  Message.error(errorMsg)
  return Promise.reject(response)
})

export default http


// 导出Excel公用方法
export function exportExcel(data,_this) {
  return axios({
		method: data.method,
		url: `${data.url}${data.params ? '?' + data.params : ''}`,
		responseType: 'blob',
		headers: {
			'wg-auth': JSON.parse(sessionStorage.getItem("token_xjfj")).tokenInfo
		},
  }).then((res) => {
		const link = document.createElement('a')
		let blob = new Blob([res.data], {
			type: 'application/vnd.ms-excel'
		})
		link.style.display = 'none'
		link.href = URL.createObjectURL(blob)
		// link.download = res.headers['content-disposition'] //下载后文件名
		link.download = data.fileName //下载的文件名
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		_this.exportOneName = "导出所选";
		_this.exportAllName = "导出全部";
  }).catch(error => {
		console.log(error)
  })
}
// 导出word公用方法
export function exportMethodWord(data) {
  return axios({
		method: data.method,
		url: `${data.url}`,
		data:data.params,
		//data:qs.stringify(data.params),
		responseType: 'blob',
		//opts:{responseType: "arraybuffer"},
		headers: {
			'wg-auth': JSON.parse(sessionStorage.getItem("token_xjfj")).tokenInfo
		},
  }).then((res) => { 
		const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/msword"}));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.download = data.fileName; //下载的文件名
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
  }).catch(error => {
		console.log(error)
  })
}
// 导出pdf公用方法
export function exportPdf(data) {
  return axios({
		method: data.method,
		url: `${data.url}`,
		data:data.params,
		//data:qs.stringify(data.params),
		responseType: 'blob',
		//opts:{responseType: "arraybuffer"},
		headers: {
			'wg-auth': store.state.tokenInfo
		},
  }).then((res) => { 
		const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		//link.download = data.fileName; //下载的文件名
		link.target = '_blank'; //下载的文件名
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
  }).catch(error => {
		console.log(error)
  })
}
// 导出zip公用方法
export function exportZip(data) {
  return axios({
		method: data.method,
		url: `${data.url}`,
		data:data.params,
		//data:qs.stringify(data.params),
		responseType: 'blob',
		//opts:{responseType: "arraybuffer"},
		headers: {
			'wg-auth': JSON.parse(sessionStorage.getItem("token_xjfj")).tokenInfo
		},
  }).then((res) => { 
		const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/zip"}));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.download = data.fileName; //下载的文件名
		link.target = '_blank'; //下载的文件名
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
  }).catch(error => {
		console.log(error)
  })
}