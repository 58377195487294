import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)

/* //获取原型对象上的pusk函数 
const originalPush = VueRouter.prototype.push；
//修改获取原型对象上的pusk方法
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err => err)
} */

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
		component: () => import('../views/Login.vue')
  },
  {
    path: '/index',
    name: 'Index',
		component: () => import('../views/Index.vue'),
		children:[
			{
				path: '/overallSituation',
				name: 'overallSituation',
				component: () => import('../views/overall-situation.vue')
			},
			{
				path: '/institutionalPortrait',
				name: 'InstitutionalPortrait',
				component: () => import('../views/monitoring-warning/institutional-portrait.vue')
			},
			{
				path: '/institutionalPortraitInfo',
				name: 'InstitutionalPortraitInfo',
				component: () => import('../views/monitoring-warning/institutiona-portrait-info/info-home.vue')
			},
			{
				path: '/institutionalPortraitInfoRiskAudit',
				name: 'Institutiona-portrait-info-audit',
				component: () => import('../views/monitoring-warning/institutiona-portrait-info/info-risk-audit.vue')
			},
			{
				path: '/institutionalPortraitInfoOnekeyReport',
				name: 'Institutiona-portrait-onekey-info-report',
				component: () => import('../views/monitoring-warning/institutiona-portrait-info/info-onekey-report.vue')
			},
			{
				path: '/warningAudit',
				name: 'WarningAudit',
				component: () => import('../views/monitoring-warning/warning-audit.vue')
			},
			{
				path: '/clueDiscovery',
				name: 'ClueDiscovery',
				component: () => import('../views/clue-discovery.vue')
			}, 
			{
				path: '/caseSituation',
				name: 'CaseSituation',
				component: () => import('../views/case-situation.vue')
			}, 
			{
				path: '/monitoringReport',
				name: 'MonitoringReport',
				component: () => import('../views/monitoring-report.vue')
			}, 
			{
				path: '/countryIssueProvince',
				name: 'countryIssueProvince',
				component:()=> import('../views/cooperationl-linkage/country-issue-province.vue')
			},
			{
				path: '/waitReportCountry',
				name: 'waitReportCountry',
				component:()=> import('../views/cooperationl-linkage/wait-report-country.vue')
			},
			{
				path: '/alreadyReportCountry',
				name: 'alreadyReportCountry',
				component:()=> import('../views/cooperationl-linkage/already-report-country.vue')
			},
			{
				path: '/waitIssueCity',
				name: 'waitIssueCity',
				component:()=> import('../views/cooperationl-linkage/wait-issue-city.vue')
			},
			{
				path: '/alreadyIssueCity',
				name: 'alreadyIssueCity',
				component:()=> import('../views/cooperationl-linkage/already-issue-city.vue')
			},
			{
				path: '/waitReportProvince',
				name: 'waitReportProvince',
				component:()=> import('../views/cooperationl-linkage/wait-report-province.vue')
			},
			{
				path: '/alreadyReportProvince',
				name: 'alreadyReportProvince',
				component:()=> import('../views/cooperationl-linkage/already-report-province.vue')
			},
			{
				path: '/caseAdministrative',
				name: 'caseAdministrative',
				component:()=> import('../views/cooperationl-linkage/case-administrative.vue')
			},
			{
				path: '/caseCriminal',
				name: 'caseCriminal',
				component:()=> import('../views/cooperationl-linkage/case-criminal.vue')
			},
			{
				path: '/usageStatistics',
				name: 'usageStatistics',
				component: () => import('../views/system-management/statistics.vue')
			},
			{
				path: '/uesrManagement',
				name: 'uesrManagement',
				component: () => import('../views/system-management/uesr.vue')
			},
			{
				path: '/roleManagement',
				name: 'roleManagement',
				component: () => import('../views/system-management/role.vue')
			},
			{
				path: '/jurisdictionManagement',
				name: 'jurisdictionManagement',
				component: () => import('../views/system-management/jurisdiction.vue')
			},
			{
				path: '/departmentManagement',
				name: 'departmentManagement',
				component: () => import('../views/system-management/department.vue')
			},
			{
				path: '/operationManagement',
				name: 'operationManagement',
				component: () => import('../views/journal-management/operation.vue')
			},
			{
				path: '/abnormalManagement',
				name: 'abnormalManagement',
				component: () => import('../views/journal-management/abnormal.vue')
			},
			{
				path: '/configureManagement',
				name: 'configureManagement',
				component: () => import('../views/journal-management/configure.vue')
			},
		]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,//    /xjfj/
  routes
})

export default router
