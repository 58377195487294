export const config = {
  development: {
    serverUrl: 'https://jinan.wengegroup.com',
  },
  production: {
    serverUrl: '',
  },
}

export const serverUrl = config[process.env.NODE_ENV].serverUrl;

