import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		tokenInfo: sessionStorage.getItem("token_xjfj") ? JSON.parse(sessionStorage.getItem("token_xjfj")).tokenInfo : "",
		//tokenInfo: "",
  },
  mutations: {
		commitToken(state,data){
      state.tokenInfo = data
		}
  },
  actions: {},
  modules: {},
  /* plugins: [createPersistedState({
		reducer(val) {
			return {
				tokenInfo: val.tokenInfo
			}
		}
  })] */
})
